import React from 'react'
import {Layout} from 'layouts'
import {Trans, useTranslation} from 'react-i18next'
import {StaticImage} from 'gatsby-plugin-image'
import Cupshe from './logos/Cupshe'

function Page() {
  const {t} = useTranslation()
  return (
    <Layout mainClassName="">
      <div className="bg-[#1B0053] xl:bg-transparent xl:bg-[url(assets/images/bg-case-study.png)] bg-cover bg-center bg-no-repeat relative">
        <div className="container mx-auto pb-60 px-4 lg:px-0 h-[300px]">
          <Cupshe className="w-40 fill-white -ml-4" />
          <h1 className="mb-12 max-w-xl text-4xl text-white font-bold">
            “<Trans>{t('Evet Guard is like a guardian')}</Trans>”
          </h1>
          <div className="text-white text-lg">
            <span className="font-bold">
              <Trans>{t('Merchant Name')}</Trans>:{' '}
            </span>
            CUPSHE
          </div>
          <div className="text-white mt-4 text-lg">
            <span className="font-bold">
              <Trans>{t('Website')}</Trans>:{' '}
            </span>
            cupshe.com
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 lg:px-0">
        <p className="font-extralight text-xl text-gray-500 mt-10 leading-[40px]">
          <Trans>
            {t(
              "Cupshe is a developing global women's fashion DTC brand. We have official sites in the United States, Canada, Australia, the United Kingdom, German, France and other countries"
            )}
            .
          </Trans>{' '}
        </p>
        <h2 className="text-xl font-bold text-gray-700 mt-10 leading-[40px]">
          <Trans>
            {t(
              'What problems were you seeing that prompted you to look into search monitoring?'
            )}
          </Trans>
        </h2>
        <p className="text-xl text-gray-500 leading-[40px]">
          Cupshe affiliate program launched in 2015. And in 2020,
          we released our PPC policy. It prevents the use of TM
          terms in domain names, subdomains and etc.{' '}
        </p>
        <p className="text-xl font-bold text-primary mt-10 leading-[40px]">
          “After a struggle year of monitoring those prohibited
          keywords manually. We longed to find a tool that is more
          effective and accurate to help us do that. Since that we
          started to go with Evet Guard.”
        </p>
        <StaticImage
          src="../../assets/images/illustrations-case-study.png"
          alt="laptop"
          placeholder="none"
          className="float-right"
        />
        <h2 className="text-xl font-bold text-gray-700 mt-20">
          How long have you been using Evet Guard?
        </h2>
        <p className="text-lg text-gray-500 mt-4">
          1 year 4 months
        </p>
        <h2 className="text-xl font-bold text-gray-700 mt-10">
          How often do you use Evet?
        </h2>
        <p className="text-lg text-gray-500 mt-4">
          Every working day.
        </p>
        <h2 className="text-xl font-bold text-gray-700 mt-10">
          How has Evet helped your business?
        </h2>
        <p className="text-lg font-bold text-primary mt-4">
          “Evet Guard is like a guardian.”
        </p>
        <p className="text-lg text-gray-500 mt-10 leading-[40px]">
          An accurate and effective solution to help us monitor
          our brand keywords with a reasonable price.{' '}
        </p>
        <p className="text-lg font-bold text-primary mt-10 leading-[40px]">
          “Now, after working with Evet Guard over a year, Cupshe
          affiliate team is able to find any new PPC violations
          within 24 hours.”
        </p>
        <p className="text-lg text-gray-500 mt-20 leading-[40px] mb-10">
          Our search team can know which competitor bids our
          trademark as well. We even found several fraud sites and
          sued them. Evet Guard protects not only our affiliate
          program and search marketing, but also brand awareness,
          in almost every country that we are in.
        </p>
        <Cupshe className="w-40 -mb-8 -ml-4" />
        <div className="text-base mt-4 mb-20">
          Janus Zhang | Senior Affiliate Manager
        </div>
      </div>
    </Layout>
  )
}

export default Page
